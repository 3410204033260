/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc The first component of the application, all the required context and routes are used here 
 * to setup the application.
 */

import React from "react";

import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";
import { CssBaseline } from "@material-ui/core";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
import SectionData from './contexts/sectionContext/sectionContext'
import AppDrawer from './App.drawer'


function App() {
  return (
    <AppAuth>
      <AppErrorBoundary>
        <AppTheme>
          <CssBaseline />
          <AppAlert>
            <AppDialog>
              <AppBackdrop>
                <SectionData>
                <AppDrawer>
                    <RouterApp />
                  </AppDrawer>
                  </SectionData>
              </AppBackdrop>
            </AppDialog>
          </AppAlert>
        </AppTheme>
      </AppErrorBoundary>
    </AppAuth>
  );
}

export default App;
