/**
 * @author Sri Rahul
 * @email rahul@qdmplatforms.com
 * @create date 2021-02-24 14:51:22
 * @modify date 2021-02-24 14:51:22
 * @desc [description]
 */


import React from "react";
import { makeStyles, Avatar, IconButton,List as MUList,ListItem,ListItemIcon,ListItemText,TextField,Popover,Button,Typography,} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  root:{

  },
  users: {
    // marginBottom: 4,
    display: "flex",
    alignItems: "center",
  },
  addBtn: {
    width: 25,
    height: 25,
    fontSize: 13,
    // zIndex:1,
    backgroundColor: "#bdbdbd",
    color: "#fff",
  },
  userList:{
    minWidth:220,
    '& .MuiTextField-root':{
        padding:10,
        '& .MuiInputLabel-formControl':{
          padding:10,
        }
    },
    '& .MuiList-dense':{
        minHeight:220,
        maxHeight:220,
        overflow:'auto'
    }
  },
  addPeople:{
    color:'#7B7B84',
    fontSize:12,
    fontWeight: 500
  },
  saveBtn:{
    backgroundColor:'#E34D65',
    color:'#fff',  
},
okBtnDiv:{
  textAlign:'right',
  padding:'0px 10px 10px',
  '& .MuiButton-contained':{
    '&:hover':{
        backgroundColor:'#E34D65 !important'
    }
}
}
}));

export const UserList = (props) => {
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (text) => {
      
    if(text !== 'fromAddPeople'){
      setUserAdd(props?.selectedUsers ?? [])
    }
    setValue('')
    setAnchorEl(null);
    setListUser(props?.userList)
  };

  // console.log(props?.selectedUsers)


  const [value,setValue] = React.useState('');
  
  let list  = []
    if(props.userList){
        list = JSON.parse(JSON.stringify(props.userList));
    }

  const [listUser,setListUser] = React.useState(list)
  
  
  const onChangeInput = (e) =>{
      
    const filteredUser = [];

    setValue(e.target.value)

    props?.userList && props.userList.forEach(user=>{
        if(user.user_name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1){
            filteredUser.push(user)
        }
    })

    setListUser(filteredUser)
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  
  const [userAdd,setUserAdd] = React.useState(props?.selectedUsers ?? [])
  
  const addPeople = () =>{
    handleClose('fromAddPeople');
    props.addUser(props?.sectionId,props?.questionId,userAdd);
  }

  const selectPeople = (data) =>{
    
    const check = userAdd && userAdd?.find(d=>d.user_id === data.user_id);
    if(check){
      const filteredData = userAdd?.filter(_=>_.user_id !== data.user_id);
      setUserAdd([...filteredData])
  }
  else{
      setUserAdd([...userAdd,data])
  }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.addPeople}>ADD PEOPLE</Typography>      
    <div className={classes.users}>
      {props?.selectedUsers && (
        <AvatarGroup className={"topnav-avatar"} max={4}>
          {props?.selectedUsers.map((user) => {
            return (
              <Avatar key={user.user_id}>{user.user_name.charAt(0)}</Avatar>
            );
          })}
        </AvatarGroup>
      )}
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        className={classes.addBtn}
        style={{ marginLeft: props?.selectedUsers?.length > 0 ? "-10px" : 0 }}
      >
        <AddIcon fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
        <div className={classes.userList}>
        <TextField 
            id="standard-basic"
            label="Search for people"
            fullWidth
            onChange={e => onChangeInput(e)}
            value={value ?? ''}
            name="value"
            />
        <MUList dense>
            {
                listUser && listUser.map(user=>{
                    return(
                        <ListItem
                            key={user.user_id}
                            dense
                            button
                            onClick={() => {
                            selectPeople(user)
                            }}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <Avatar alt={user.user_id} src={user?.user_pic && user?.user_pic}>
                                    {user.user_pic ?? user.user_name.charAt(0)}
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText primary={user.user_name} />

                            {
                                userAdd && userAdd.find(su=>su.user_id === user.user_id) &&
                                <ListItemIcon style={{
                                    display:'flex',
                                    justifyContent:'flex-end'
                                }}>
                                    <CheckIcon fontSize="small" htmlColor="green" />
                                </ListItemIcon>
                            }

                      </ListItem>         
                    )
                })
              }
        </MUList>
          <div className={classes.okBtnDiv}>
          <Button variant="contained" onClick={addPeople} className={classes.saveBtn}>Okay</Button>
          </div>
        </div>
      </Popover>
      </div>      
    </div>
  );
};
