/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Providing the AuthContext from /src/context which is used in the /src/App.js.
 */

import React from "react";
import { LinearProgress } from "@material-ui/core";
import { NetworkCall } from "./networkcall";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // localStorage.setItem( "metaId", "8f762af2-14ba-4e09-bd22-2b1845175d2a")
    // localStorage.setItem( "metaId", "c6ab4ef9-7e15-482c-be57-21237e58f21a")
    NetworkCall('meta.json', 'GET', null, null, false, true).then((_) => {
      localStorage.setItem('version', _.data.version)
    }).catch(err => {
      console.log('err:', err);
    })
  }

  render() {

    let {
      loading
    } = this.props;

    return (
      <>
        {loading && (
          <LinearProgress />
        )}
        {this.props.children}
      </>
    );
  }
}

export default AppAuth;
