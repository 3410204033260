/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc withNavBars HOC will give you the Navbars (Top/Bottom Navbars)
 */

import React from "react";
import { makeStyles, Grid, Typography, Avatar } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import {SecondTopNav} from '../components'

const useStyles = makeStyles((theme) => ({
  root1: {
    flexGrow: 1,
    width: '100%'
  },
  content: {
    width: '100%'
  },
  paper: {
    borderRadius: 0,
    borderBottom:'1px solid #F2F1F3'
  },
  title: {
    padding: "5px 10px",
    color:"#0b78fa"
    // color: "#5d5df7"
  },
  username: {
    fontSize: "12px",
    fontWeight: 600,
    margin: "auto"
  },
  rounded: {
    width: "22px",
    height: "22px",
    fontSize: "11px",
    margin: "auto",
    background: "black",
    color: "blue"
  },
  rightalign: {
    textAlign: "right",
    display: "grid",
    gridTemplateColumns: "30px 88px 26px",
    float: "right",
    padding: "8px"
  },
  topBar:{
    position:'sticky',
        top:-42,
        zIndex:9,
        backgroundColor:'#fff'
  },

}));


const withNavBars = (Component) => (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <div className={classes.topBar}>
      <div className={classes.paper}>
        <Grid container className={classes.root1} >
          <Grid item md={6} style={{flex:1}}>
            <Typography variant="h6" className={classes.title} >QDM</Typography>
          </Grid>
          <Grid item md={6}>
            <div className={classes.rightalign}>
              <Avatar variant="rounded" className={classes.rounded}>DP</Avatar>
              <Typography variant="h6" className={classes.username} >Dmirch Preach</Typography>
              <ExpandMoreIcon />
            </div>
          </Grid>
        </Grid>
      </div>
      </div>
      {/* Content */}
      <div className={classes.content}>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
