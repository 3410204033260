/**
 * @author Sri Rahul
 * @email rahul@qdmplatforms.com
 * @create date 2021-02-26 18:04:10
 * @modify date 2021-02-26 18:04:10
 * @desc [description]
 */

import React from 'react';
import { Grid, Paper, Avatar, Divider, Typography, Button,Snackbar,Chip} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import axios from 'axios';
// import { uuid } from "uuidv4";
import {DrawerProps,Message} from '../../utils';
import {withAllContexts} from '../../HOCs';
import {ManageKPI} from '../manageKPI';
import environment from '../../config';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    paper: {
        borderRadius: 0,
        padding: "8px"
    },
    button: {
        height: "30px",
        width: "187px",
        color: "unset !important",
        marginRight: 10,
        borderRadius: "6px"
    },
    buttonactive: {
        color: "white !important",
    },
    middle: {
        textAlign: "center"
    },
    avatar: {
        width: 27,
        height: 27,
        fontSize: 13
    },
    right: {
        justifyContent:'flex-end',
        display: "flex",
        '& .MuiButton-contained':{
            '&:hover':{
                backgroundColor:'#E34D65 !important'
            }
        },
    [theme.breakpoints.down("sm")]: {
        // justifyContent:'flex-start',
        marginTop:5
        },
    },
    divider: {
        margin: "0px 10px"
    },
    left : {
        display: "flex",
        alignItems:'center',
        flex:1
    },
    dbName : {
        fontSize : 15,
        marginRight:10
    },
    buttonFontStyle : {
        textTransform: 'none'
    },
    buttonBackground:{
        background: "#fafafb !important"

    },
    flexCenter:{
        display:'flex',
        alignItems:'center'
    },
    saveBtn:{
        backgroundColor:'#E34D65',
        color:'#fff',  
    },
    snackBr:{
        '& .MuiSnackbar-root':{
            zindex:2,
            position:'absolute'
        },
        '& .MuiSnackbarContent-root':{
            backgroundColor:'#4CAF50',
            display:'flex',
            justifyContent:'center',
            zIndex:2
        }
    },
    kpiBtn:{
        borderColor:'#E34D65',
        color:'#E34D65',
        marginRight:10
    },
    chip:{
        position:'absolute',
        left:'50%',
        transform:'translate(-50%)',
        '& .MuiChip-root':{
            backgroundColor:'#E34D65',
            color:'#fff',
        },
        '& .MuiChip-icon':{
            color:'#fff'
        },
        [theme.breakpoints.down("sm")]: {
          bottom:'15px',
          left:'10px'  ,
          transform:'translate(0px)',    
        },
    }
}));

class SecondTopNav extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open:false,
            isSaveChanges:false,
        }
    }

   
    componentWillReceiveProps(nextProps){
        if(nextProps.isEdit){
            this.setState({...this.state,isSaveChanges:true})
        }
        else{
            this.setState({...this.state,isSaveChanges:false})
        }
    }

    handleClick = () => {
      this.setState({...this.state,open:true});
    };
  
    handleClose = () => {
     
    this.setState({...this.state,open:false});
    };

    saveApi = () =>{
        this.SaveSchema()
    } 

    // kpiList = [
    //     {
    //         value:'MRR',
    //         label:'MRR'
    //     },
    //     {
    //         value:'CLTV',
    //         label:'CLTV'
    //     },
    //     {
    //         value:'CAC',
    //         label:'CAC'
    //     },
    //     {
    //         value:'Daily Active Users',
    //         label:'Daily Active Users'
    //     },
    //     {
    //         value:'Monthly Active Users',
    //         label:'Monthly Active Users'
    //     },
    //     {
    //         value:'Session Duration',
    //         label:'Session Duration'
    //     },
    //     {
    //         value:'Traffic',
    //         label:'Traffic'
    //     },
    //     {
    //         value:'Bounce Rate',
    //         label:'Bounce Rate'
    //     },
    //     {
    //         value:'Retention Rate',
    //         label:'Retention Rate'
    //     },
    //     {
    //         value:'Churn Rate',
    //         label:'Churn Rate'
    //     },
    //     {
    //         value:'Sessions Per User',
    //         label:'Sessions Per User'
    //     },
    //     {
    //         value:'User Actions Per Session',
    //         label:'User Actions Per Session'
    //     },
    //     {
    //         value:'NPS',
    //         label:'NPS'
    //     },
    //     {
    //         value:'CSAT',
    //         label:'CSAT'
    //     },
    // ]

    manageKPI = (KPI) =>{
        // const { sectionContext } = this.props;
        //     let { KPI } = sectionContext;
        const {drawer} = this.props;
        
        drawer.setDrawer({
            ...drawer,
            open: true,
            direction: DrawerProps.direction.right,
            variant: DrawerProps.variant.temporary,
            component: <ManageKPI data={KPI} actionKPI={this.actionKPI}/>,
            onClose: () => this.onActionCompleted()
          })

    }

    onActionCompleted = () =>{
        
        
    }
    
    actionKPI = (data,action) =>{
        this.props.setIsEdit(true);
        const { sectionContext } = this.props;
            let { setSectionArray,KPI,list } = sectionContext;
        if(action==='add'){
            KPI.push({
                value:data.value,
                label:data.label
              })
        } else if( action === 'delete')  {
            KPI =  KPI.filter((kpi_data,index)=> kpi_data.value !==  data.value);
        } else if( action === 'edit'){
            KPI =  KPI.map((kpi_data,index)=> {
                if(kpi_data.value ===  data.value){
                    return {
                        value:data.value ?? data.label,
                        label:data.label
                    }
                }
                return kpi_data;
            });

        }

        //KPI modified in list
        if(action !== 'add')
        {
        list = list.map(l=>{
            l?.questions && l?.questions?.length > 0 &&
             l.questions.map(m=>{
                if(m?.answer_type2?.value === data?.value){
                    if(action === 'delete'){
                        m.answer_type2={}
                    }
                    else if(action === 'edit'){
                        m.answer_type2=data
                    }
                }
                return m
                })
                return l
            })
        }
      
        setSectionArray({
            KPI,
            list
        });
        // .then((success)=>{
            this.manageKPI(KPI);

        // })
    }
     SaveSchema = async () => {

        const { sectionContext } = this.props;
        let { list,KPI } = sectionContext;

        let dataList = {
            db_name: "QDM_Metadata",
            entity: "StrategicModeler",
            is_metadata: false,
            projectname: "test",        
          doc: {
            metadataId: localStorage.getItem('metaDataId') ?? "0f9dc6ce-1d9b-4abd-a83b-462e319f7aa4",
            StrategicModelerid: localStorage.getItem('StrategicModelerid') ?? "ad8c5c3e-161f-4635-8b46-4b857fa6071e",
            projectname: localStorage.getItem('projectName') ?? "CRAYOND3",
            attributes: list,
            KPI:KPI
          },
        };
        if (localStorage.strategicModelerid) {
          dataList.filter = { StrategicModelerid: dataList.StrategicModelerid };
        }
        let data = JSON.stringify([dataList]);
        var config = {
          method: "post",
          url: `${environment.api_url}/api/upsert_document`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios;
        axios(config)
          .then((response) => {
            localStorage.setItem(
              "strategicModelerid",
              response.data.Result[0].properties.doc.StrategicModelerid
            );
            localStorage.setItem(
              "metadataId",
              response.data.Result[0].properties.doc.metadataId
            );
            this.handleClick();
            this.props.setIsEdit(false);
            this.setState({...this.state,isSaveChanges:false});
          })
          .catch((err) => {
            alert("something went wrong!");
          });
      };

      

      render() {
        
          const { classes,sectionContext } = this.props;
          let { KPI } = sectionContext;
        
        return (
            <Paper className={classes.paper}>
                <Grid container className={classes.root}>
                    <Grid item xs={12} md={10} className={classes.left}>
                        <div className={classes.flexCenter} style={{flex:1}}>
                        <Avatar variant="rounded"  className={classes.avatar}>A</Avatar>
                        <Divider orientation="vertical" flexItem className={classes.divider} />
                        <div className={classes.flexCenter}>
                            <Typography variant="h6" className={classes.dbName} >
                                {this?.props?.headerName.length > 0 ? this?.props?.headerName : 'Foodzo'}
                                {/* Foodzo */}
                            </Typography>
                                {/* <img src={require('../../assets/editTopNav.svg')} style={{cursor:'pointer'}} alt="editTop"/> */}
                                
                                {
                                    this.state.isSaveChanges &&
                                    <div className={classes.chip}>
                                    <Chip
                                        icon={<SaveIcon htmlColor="#fff" />}
                                        label="Please save your changes"
                                        size="small"
                                    />
                                    </div>
                                }

                            </div>
                            </div>
                                <div style={{marginRight:10}} className={classes.flexCenter}>
                                <AvatarGroup className={'topnav-avatar'} max={4}>
                                    <Avatar className={classes.avatar}>A</Avatar>
                                    <Avatar className={classes.avatar}>A</Avatar>
                                    <Avatar className={classes.avatar}>A</Avatar>
                                    <Avatar className={classes.avatar}>A</Avatar>
                                    <Avatar className={classes.avatar}>A</Avatar>
                                </AvatarGroup>
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                                <Avatar className={classes.avatar}>A</Avatar>
                                </div>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.right}>
                        <div className={classes.flexCenter} >
                            <Button variant="outlined" onClick={()=>this.manageKPI(KPI)} className={classes.kpiBtn}>Manage KPIs</Button>
                            <Button variant="contained" onClick={this.saveApi} className={classes.saveBtn}>
                               save
                            </Button>
                        </div>
                    </Grid>
                </Grid> 
                <div className={`${classes.snackBr}`}>
                    <Snackbar
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={3000}
                        onClose={this.handleClose}
                        message={
                        <div>   
                                <div className={classes.flexCenter}>
                                    <CheckCircleOutlineIcon htmlColor="#fff" fontSize="small" style={{marginRight:10}}/>{Message.saveSuccess} 
                                </div>
                        </div>
                        }
                    />
                </div>
            </Paper>
        )
    }
}

export default withStyles(useStyles)(withAllContexts(SecondTopNav));