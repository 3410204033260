/**
 * @author Sri Rahul
 * @email rahul@qdmplatforms.com
 * @create date 2021-02-24 14:51:22
 * @modify date 2021-02-24 14:51:22
 * @desc [description]
 */

import React from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  IconButton,
  ClickAwayListener
} from "@material-ui/core";
// import { Prompt } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { withAllContexts, withNavBars } from "../../HOCs";
import { SectionHeader } from "./sectionHeader";
import { SectionBody } from "./sectionBody";
import { Message } from "../../utils";
import { uuid } from "uuidv4";
import {SecondTopNav} from '../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  sectionBody: {
    minHeight: 300,
    maxHeight: 300,
    overflowY: "auto",
  },
  sectionWrapper: {
    padding: 12,
    minHeight: 200,
  },
  container: {
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      // paddingTop:30,
    },
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #0000001A",
    borderRadius: "10px",
    height: "100%",
  },
  addSection: {
    cursor: "pointer",
  },
  questionSection: {
    padding: theme.spacing(1),
    backgroundColor: "#F7F7F7",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
  },
  addQuestionSection: {
    padding: "8px 10px",
    "& .MuiInput-root": {
      marginBottom: 10,
    },
  },
  addBtn: {
    color: "#E34D65",
    padding: 0,
    display: "flex",
    justifyContent: "flex-start",
    "&:hover": {
      background: "none",
    },
  },
  secTopNav:{
    position:'sticky',
    top:0,
    zIndex:10,
    backgroundColor:'#fff'
  }
}));

export const Section = (props) => {
  const [state, setState] = React.useState({
    sectionType: "",
    question: "",
    sectionId: "",
    projectName:'',
    error: {
      question: false,
    },
  });

  //edit
  
  const onUnload = (e) =>{
    
    e.preventDefault();
    if (isEdit) {
      e.returnValue = "";
      }
    }
    
    const [isEdit,setIsEdit] = React.useState(false);

    React.useEffect(() => {
      window.addEventListener("beforeunload", onUnload);
        return () => {
          window.removeEventListener("beforeunload", onUnload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[isEdit])



  //show or hide question input
  const [showInput, setShowInput] = React.useState(false);

  const onChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const addSection = () => {

      const newSection = {
        section_id: uuid(),
        section_title: 'Untitled Section',
        section_type: {},
      };

      list.push(newSection);

      setIsEdit(true)

      setSectionArray({
        ...sectionContext,
        list,
      });

  };

  const addQuestion = (data) => {
    setState({ ...state, sectionId: data.section_id, question: "" });
    setShowInput(true);
  };

  const keydownFunc = (event) => {
    if (event.keyCode === 27) {
      blurAndEscEvt();
    } else if (event.keyCode === 13) {
      addQuestionToSection();
    }
  };

  const blurAndEscEvt = () => {
    setShowInput(false);
    setState({...state,error:{
      question:false
    }})
  };

  const addQuestionToSection = () => {
    if (state.question.length === 0) {
      state.error = {
        question: state?.question?.length === 0 ? true : false,
      };
      setState({ ...state });
    } else {
      const questionObj = {
        question_id: uuid(),
        question_name: state.question,
      };

      list &&
        list.forEach((_) => {
          if (_.section_id === state.sectionId) {
            if (Array.isArray(_.questions)) {
              _.questions.push(questionObj);
            } else {
              _["questions"] = [{ ...questionObj }];
              setIsEdit(true)
            }
          }
        });

      setSectionArray({
        ...sectionContext,
        list,
      });

      setShowInput(false);
    }
  };

//   const onEditIconClicked = (sectionID) => {};

  const onDeleteIconClicked = (sectionID) => {
    const filteredData = list && list.filter((_) => _.section_id !== sectionID);
    setIsEdit(true)
    setSectionArray({
      ...sectionContext,
      list: filteredData,
    });
  };

  const changeSectionType = (sectionId, value) => {

    list &&
      list.forEach((_) => {
        if (_.section_id === sectionId) {
          _.section_type = value;
          setIsEdit(true)
        }
      });

    setSectionArray({
      ...sectionContext,
      list,
    });

  };

  const editSectionTitle = (title, sectionId) => {
    list &&
      list.forEach((_) => {
        if (_.section_id === sectionId) {
          _.section_title = title;
          setIsEdit(true)
        }
      });

    setSectionArray({
      ...sectionContext,
      list,
    });
  };

  React.useEffect(()=>{

    if(props?.location?.search&&props?.location?.search?.length>0){
      let params  = new URLSearchParams(props.location.search)
      localStorage.setItem('projectName',params.get('projectName'));
      setState({
        ...state,projectName:params.get('projectName')
      })
      localStorage.setItem('metaDataId',params.get('metaDataId'));
      localStorage.setItem('StrategicModelerid',params.get('StrategicModelerid'));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const classes = useStyles(props);

  let { sectionContext } = props;

  let { setSectionArray, list } = sectionContext;


  return (
    <div className={classes.root}>
       <Grid item md={12} className={classes.secTopNav}>
              <SecondTopNav 
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                headerName={state.projectName}
              
              />
          </Grid>
      <Grid container className={classes.container}>
        {sectionContext.list &&
          sectionContext.list.map((_) => {
            return (
              <Grid
                key={_.section_id}
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                className={classes.sectionWrapper}
              >
                <div className={classes.paper}>
                  <SectionHeader
                    title={_.section_title}
                    sectionId={_.section_id}
                    sectionType={_.section_type}
                    IconType={_.icon_type}
                    changeSectionType={changeSectionType}
                    // onEditIconClicked={onEditIconClicked}
                    onDeleteIconClicked={onDeleteIconClicked}
                    editSectionTitle={editSectionTitle}
                    list={list}
                  />
                  <div className={classes.sectionBody}>
                    {_.questions &&
                      _.questions.map((val) => {
                        return (
                          <div key={_.question_id}>
                            <SectionBody
                              questionId={val.question_id}
                              questionName={val.question_name}
                              answer={val.answer}
                              answerType={val.answer_type}
                              answerType2={val.answer_type2}
                              users={val.users}
                              sectionId={_.section_id}
                              isEdit={isEdit}
                              setIsEdit={setIsEdit}
                            />
                            <Divider />
                          </div>
                        );
                      })}
                    {showInput && state.sectionId === _.section_id && (
                      <div className={classes.addQuestionSection}>
                        <div>
                        <ClickAwayListener onClickAway={blurAndEscEvt}>
                            <TextField
                              autoFocus
                              onChange={(e) => onChangeInput(e)}
                              value={state.question}
                              fullWidth
                              name="question"
                              label="Type Question"
                              onKeyDown={(e) => keydownFunc(e)}
                              // onBlur={blurAndEscEvt}
                              error={state.error.question}
                              helperText={
                                state.error.question === true
                                  ? Message.requiredMessage
                                  : ""
                              }
                            />
                          </ClickAwayListener>
                          <Button
                            variant="text"
                            className={classes.addBtn}
                            onClick={() => addQuestionToSection()}
                          >
                            + Add
                          </Button>
                        </div>
                      </div>
                    )}
                    {(!showInput || (state.sectionId !== _.section_id)) &&
                    <Button
                      variant="text"
                      className={classes.addBtn}
                      style={{ padding: "15px" }}
                      onClick={() => addQuestion(_)}
                    >
                      + Add Question
                    </Button>
                  }
                  </div>
                </div>
              </Grid>
            );
          })}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          className={classes.sectionWrapper}
        >
          <div
            className={`${classes.paper} ${classes.flexCenter}`}
            style={{ justifyContent: "center" }}
          >
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={addSection}
            >
              <IconButton>
                <AddIcon fontSize="large" htmlColor="#E34D65" />
              </IconButton>
              <Typography variant="h6" style={{ color: "#E34D65" }}>
                {" "}
                Add Section
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <Prompt
          when={isEdit}
          message={() =>
            `Are you sure you want to go to close`
          }
        /> */}
    </div>
  );
};
export default withNavBars(withAllContexts(Section));
