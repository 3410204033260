
import React from "react";
import { SectionContext } from "../";
import axios from 'axios';
import environment  from '../../config';
const SectionData = (props) => {

    let [sectionArray, setSectionArray] = React.useState({
        list: [
            {
                section_id:'sectionID1',
                section_title:'Key Partners',
                icon_type:{icon:'keyPartners.svg',bgColor:'#FFF9E2'},
                // questions:[
                //     {
                //         question_id:'questionID1',
                //         question_name:'Who are our key partners?',
                //         answer: "Large universities often develop research in partnership with industries, which store these surveys to be able to use them in business",
                //         answer_type:{value:'objective',label:'Objective'},
                //         answer_type2:{value:'strategic',label:'strategic'},
                //         users:[
                //                 {
                //                     user_id:'userID1',
                //                     user_name:'Ashwin',
                //                     user_pic:'https://www.w3schools.com/howto/img_avatar.png'
                //                 },
                //                 {
                //                     user_id:'userID2',
                //                     user_name:'Kiran',
                //                     user_pic:'https://www.w3schools.com/howto/img_avatar.png'
                //                 },
                //             ]
                //     },
                // ]
            },
            {
                section_id:'sectionID2',
                section_title:'Key Activities',
                icon_type:{icon:'keyActivities.svg',bgColor:'#E8F4FF'},
            },
            {
                section_id:'sectionID3',
                section_title:'Value Proposition',
                icon_type:{icon:'valueProposition.svg',bgColor:'#F8E9FF'},
            },
            {
                section_id:'sectionID4',
                section_title:'Customer Segments',
                // section_type:{value:'objective',label:'Objective'},
                icon_type:{icon:'customerSegments.svg',bgColor:'#FFE9F8'},
            },
            {
                section_id:'sectionID5',
                section_title:'Key Resources',
                icon_type:{icon:'keyResources.svg',bgColor:'#D8FEDB'},
            },
            {
                section_id:'sectionID6',
                section_title:'Channels',
                icon_type:{icon:'channels.svg',bgColor:'#D4F8FF'},
            },
            {
                section_id:'sectionID7',
                section_title:'Cost Structure',
                icon_type:{icon:'costStructure.svg',bgColor:'#F4E8FF'},
            },
            {
                section_id:'sectionID8',
                section_title:'Customer Relationship',
                icon_type:{icon:'customerRelationship.svg',bgColor:'#FFE1F4'},
            },
            {
                section_id:'sectionID9',
                section_title:'Revenue Streams',
                icon_type:{icon:'revenueStreams.svg',bgColor:'#D9FFF2'},
            },
            {
                section_id:'sectionID10',
                section_title:'Increase Revenue',
            },
            {
                section_id:'sectionID11',
                section_title:'Transform Business',
            },
            {
                section_id:'sectionID12',
                section_title:'Grow Business',
            },
            {
                section_id:'sectionID13',
                section_title:'Reduce Cost',
            },
        ],
        KPI:[],
        strategic_master:[]

    });

    React.useEffect(() => {

        if (!localStorage.StrategicModelerid) {
          return false;
        }
        init();
        
             // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [localStorage.strategicModelerid]);
     const init = async() =>{
        let value = localStorage.StrategicModelerid ?? 'ad8c5c3e-161f-4635-8b46-4b857fa6071e';
        let list = [...sectionArray.list];
        let KPI = [];
        let strategic_master = [];
       await axios
          .post(`${environment.api_url}/api/read_documents`, {
            db_name: `${environment.database}`,
            entity:  `${environment.stratigic_entity}`,
            filter: `${environment.stratigic_entity}.StrategicModelerid == '${value}'`,
            return_fields: `{${environment.stratigic_entity}}`
          })
          .then((response) => {
            if (
             
                response.data.result[response.data.result.length - 1].StrategicModeler
              
            ) {
                
                    list = response?.data?.result[response.data.result.length - 1]?.StrategicModeler?.attributes ? response?.data?.result[response.data.result.length - 1]?.StrategicModeler?.attributes : sectionArray.list;
                    KPI = response.data.result[response.data.result.length - 1].StrategicModeler.KPI ? response.data.result[response.data.result.length - 1].StrategicModeler.KPI :[];
              
            }
          })
          .catch((err) => { });
          await axios
          .post(`${environment.api_url}/api/read_documents`, {
            db_name: `${environment.database}`,
            entity:  `${environment.kpi_master_entity}`,
            return_fields: `{${environment.kpi_master_entity}}`
          })
          .then((response) => {
            if (
             
                response.data.result[response.data.result.length - 1].KPI_master.KPI
              
            ) {
            
                   
                        KPI= KPI.length<=0 ? response.data.result[response.data.result.length - 1].KPI_master.KPI ? response.data.result[response.data.result.length - 1].KPI_master.KPI :[] : KPI
               
             
                
            }
          })
          .catch((err) => { });
          await axios
          .post(`${environment.api_url}/api/read_documents`, {
            db_name: `${environment.database}`,
            entity: `${environment.stratigic_master}`,
            return_fields: `{${environment.stratigic_master}}`
          })
          .then((response) => {
            if (
             
                response.data.result[response.data.result.length - 1].strategic_master.strategic_master
              
            ) {
            
                    
                        strategic_master=response.data.result[response.data.result.length - 1]?.strategic_master?.strategic_master ? response.data.result[response.data.result.length - 1].strategic_master.strategic_master : []

            
             
                
            }
          })
          .catch((err) => { });
          debugger;
          setSectionArray({
            ...sectionArray,
            list,
            KPI,
            strategic_master

      });
     }
    return (
        <SectionContext.Provider value={{ ...sectionArray, setSectionArray }}>
            {props.children}
        </SectionContext.Provider>
    );
};

export default SectionData;
