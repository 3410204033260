import React from "react";
import {
  makeStyles,
  FormControl,MenuItem,Select,InputLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const SelectComponent = (props) => {
  const classes = useStyles();
  
  const [openSelect, setOpenSelect] = React.useState(false);

  const handleChange = (event) => {
    props.onChange(event.target);
  };

  const handleSelectClose = () => {
      setOpenSelect(false);
  };

  const handleSelectOpen = () => {
      setOpenSelect(true);
  };

  return(
    <div className={classes.root}>
       <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">{props?.label}</InputLabel>
            <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={openSelect}
                onClose={handleSelectClose}
                onOpen={handleSelectOpen}
                value={props?.value.value}
                onChange={handleChange}
                >
                    {
                        props?.data && props?.data.map(_=>{
                            return(
                                <MenuItem value={_.value}>{_.label}</MenuItem>
                            )
                        })
                    }
            </Select>
        </FormControl>
    </div>
  );
};

SelectComponent.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,//[{value:'',label:''}]
  value:PropTypes.object,
  onChange:PropTypes.func
};

SelectComponent.defaultProps = {
label:'Select'
};
