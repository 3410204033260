/**
 * @author Sri Rahul
 * @email rahul@qdmplatforms.com
 * @create date 2021-02-24 14:51:22
 * @modify date 2021-02-24 14:51:22
 * @desc [description]
 */

import React from 'react';
import { Typography,Popover, List as MUList,ListItem,ListItemText,Divider,TextField,ClickAwayListener } from '@material-ui/core';
import { MoreVert } from "@material-ui/icons";
import { PopoverComponent} from '../../components'
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import {Message} from '../../utils'

const useStyles = makeStyles(theme => ({
    root: {
    },
    flexCenterBetween:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    flexCenter:{
        display:'flex',
        alignItems:'center',
    },
    sectionHeader: {
        padding: '10px 16px',
        paddingRight:'4px'
    },
    svgWrapper:{
        width:30,
        height:30,
        borderRadius:100,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginRight:8
    },
    titleTypo:{
        fontSize:16,
        cursor:'pointer'
    }
}));

export const SectionHeader = (props) => {
   
        const classes = useStyles(props);

        // const textInput = React.useRef();

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };
      
        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;
        
        const [value,setValue] = React.useState(props?.sectionType ?? {})

        const onChange = (data) =>{
            
            if(value.value === data.value){
                setValue({})
                props.changeSectionType(props?.sectionId,{})
            }
            else{
                setValue(data)
                props.changeSectionType(props?.sectionId,data)
            }
        }

        const [titleValue,setTitleValue] = React.useState({
            title:props?.title ?? '',
            error:{
                title:false,
                message:''
            }
        })

        const onChangeInput = (e) =>{
            setTitleValue({...titleValue,[e.target.name]:e.target.value})
        }

        const [showInput,setShowInput] = React.useState(false);

        const dblClkEditSection = () =>{
            setShowInput(true);
            titleValue.error = {
                title: false,
            }
            setTitleValue({ ...titleValue })
        }

        const keydownFunc = (event,sectionId) =>{
            if(event.keyCode === 27) {
                blurAndEscEvt()
            }
            else if(event.keyCode === 13) {           
                if(titleValue.title.length === 0){
                    titleValue.error = {
                        title: titleValue?.title?.length === 0 ? true : false,
                    }
                    setTitleValue({ ...titleValue })
                }
                else if(props?.title!==titleValue.title && props?.list && props?.list?.filter(l=>l?.section_title?.toLowerCase() === titleValue.title?.toLowerCase())?.length > 0){
                    titleValue.error = {
                        title: true,
                        message:'Section already exists!'
                    }
                    setTitleValue({ ...titleValue })
                }
                else{
                    setShowInput(false);
                    if(props?.title!==titleValue.title){
                        props?.editSectionTitle(titleValue.title,sectionId) && props.editSectionTitle(titleValue.title,sectionId)
                    }
                }


            }
        }


        const blurAndEscEvt = () =>{
            
            setShowInput(false);
            setTitleValue({...titleValue,title:props?.title,message:''})
        }

        // console.log(textInput.current)

        return (
            <div className={classes.root}>
                <div className={classes.sectionHeader}>
                <div className={`${classes.flexCenter}`}>
                    <div className={classes.flexCenter} style={{flex:1,flexWrap:'wrap'}}>
                        <div className={classes.svgWrapper} style={{
                            backgroundColor:props?.IconType?.bgColor ?? '#F4F4F4' 
                        }}>
                            {
                                props?.IconType?.icon &&
                                <img src={require(`../../assets/${props?.IconType?.icon}`)} alt={`${props?.IconType?.icon}`} />
                            }
                        </div>
                        <div>
                            {
                                showInput ? (
                                    <ClickAwayListener onClickAway={blurAndEscEvt}>
                                    <TextField
                                        autoFocus
                                        onChange={e => onChangeInput(e)}
                                        // inputRef={textInput}
                                        value={titleValue.title}
                                        fullWidth
                                        name="title"
                                        label="Section Title"
                                        margin="dense"
                                        onKeyDown={(e)=>keydownFunc(e,props?.sectionId)}
                                        error={titleValue.error.title}
                                        // onBlur={blurAndEscEvt}
                                        helperText={
                                            titleValue.error.title === true ? titleValue.error.message ?? Message.requiredMessage : ''
                                        }
                                />
                                </ClickAwayListener>
                                ) : (
                                    <Typography variant="h6" className={classes.titleTypo} onDoubleClick={dblClkEditSection}>{props?.title}</Typography>
                                )
                            }
                            <div>
                                <PopoverComponent 
                                    data={[{value:'objective',label:'Objective'},{value:'strategic',label:'Strategic'}]}
                                    selectedValue={value}
                                    label="+ Strategic/Objective"
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconButton aria-describedby={id} onClick={handleClick}>
                        <MoreVert />
                        </IconButton>
                        <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        >
                        <MUList dense>
                            {/* Edit Icon */}
                            <ListItem
                            dense
                            button
                            onClick={(e) => {
                                handleClose();
                                dblClkEditSection();
                                // debugger
                                //  textInput.current.focus();
                                // props.onEditIconClicked(props?.sectionId);
                            }}
                            >
                            <ListItemText primary="Edit" />
                            </ListItem>

                            {/* Delete Icon */}
                            <ListItem
                            dense
                            button
                            onClick={() => {
                                handleClose();
                                props.onDeleteIconClicked(props?.sectionId);
                            }}
                            >
                            <ListItemText primary="Delete" />
                            </ListItem>
                        </MUList>
                        </Popover>
                    </div>
                </div>
                </div>
                <Divider />
            </div>
        )
}