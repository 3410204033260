/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Collection of all the routes used in the application.
 */

const Routes = {
  //Auth routes
  section: "/section",
};

export { Routes };
