import React from "react";
import {
  makeStyles,
  List as MUList,ListItem,ListItemText,Button,ListItemIcon,Typography,Grid,TextField,Divider,IconButton
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from "prop-types";
import {Message} from '../../utils'
import { v4 as uuid }  from 'uuid';
const useStyles = makeStyles((theme) => ({
  root: {
     
  },
  spacing:{
      paddingLeft:theme.spacing(2),
      paddingRight:theme.spacing(2),
  },
  header:{
      marginBottom:10,
      '& .MuiTypography-h6':{
          color:'#007AFF'
      }
  },
  containerStyle:{
    //   paddingTop:theme.spacing(2)
  },
  addBtn:{
    color:'#E34D65',
    display:'flex',
    justifyContent:'flex-start',
    '&:hover':{
        // background:'none'
    }
},
icons:{
    display:'flex',
    alignItems:'center',
    // opacity:0,
    '& .MuiListItemIcon-root':{
        minWidth:40
    }
},
textInput: {
    height: 40,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down("xs")]: {
        width: '100%',
    },
    // borderRadius: 10,
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#110F471A'
    }
},
mulist:{
    '& .MuiListItem-button':{
        '&:hover':{
            '& .makeStyles-icons-57':{
                // opacity:1,
            }
        }
    }
},
titleHead:{
    padding:theme.spacing(2)
},
addKpiTextInput:{
    padding:theme.spacing(2),
    paddingBottom:theme.spacing(3)
},
searchField:{
    position:'sticky',
    top:0,
    zIndex:1,
    padding:'10px 0px',
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
    backgroundColor:'#fff'
}
}));

export const ManageKPI = (props) => {

  const classes = useStyles();

  const [hover,setHover] = React.useState({
      display:'none',
      value:''
  })

  const [kpiData,setKpiData] = React.useState(props?.data ?? []);

  React.useEffect(()=>{
    setKpiData(props?.data ?? [])
  },[props.data])

  const [searchValue,setSearchValue] = React.useState('');

  const [editInput,setEditInput] = React.useState(false);

  const [editKPI,setEditKPI] = React.useState({
      value:'',
      label:'',
      error:{
          value:'',
          message:''
      }
  })

  const onChangeInput = (e) =>{
      setSearchValue(e);
      if(e){
          const filteredData = props?.data?.filter(l=>l?.label?.toLowerCase()?.includes(e.toLowerCase()));
          setKpiData(filteredData)
        }
        else{
          setKpiData(props?.data)
      }
  }

  const editKPIFunc = (e,data) =>{

      setEditKPI({
          ...editKPI,
            value:data.value,
            label:data.label
      })
      
      setEditInput(true)
  }

  const onChangeEditInput = (e) =>{
    setEditKPI({
        ...editKPI,label:e
    })
    
  }

  const keydownFunc = (event,kpi) =>{
    if(event.keyCode === 27) {
        
        blurAndEscEvt()
    }
    else if(event.keyCode === 13) {   
        if(editKPI.label.length === 0){
            editKPI.error = {
                value: true,
            }
            setEditKPI({ ...editKPI })
        }
        else if(kpi?.label !== editKPI?.label && props?.data?.find(l=>l.label?.toLowerCase() === editKPI.label?.toLowerCase())){
            editKPI.error = {
                value: true,
                message:'KPI already exists!'
            }
            setEditKPI({ ...editKPI })
        }
        else{
            blurAndEscEvt();
            if(kpi?.label !== editKPI?.label){
                props.actionKPI({value:kpi.value,label:editKPI.label},'edit');
            }
            // props?.editSectionTitle(titleValue.title,sectionId) && props.editSectionTitle(titleValue.title,sectionId)
        }


    }
}


const blurAndEscEvt = () =>{
    
    setEditInput(false);
    setEditKPI({
        value:'',
        error:{
            value:'',
            message:''
        }
    })

    // setEditKPI({...editKPI,title:props?.title})
}

const [addKPIInput,setAddKPIInput] = React.useState(false)

const [addKPI,setAddKPI] = React.useState({
    value:'',
    error:{
        value:'',
        message:''
    }
})

const onChangeAddInput = (e) =>{
    setAddKPI({
        ...addKPI,
        value:e
    })
}

const keydownFuncForAddKPI = (event,value) =>{
    if(event.keyCode === 27) {
        
        blurAndEscEvtForAddKPI()
    }
    else if(event.keyCode === 13) {   

        if(addKPI.value.length === 0){
            addKPI.error = {
                value: addKPI?.value?.length === 0 ? true : false,
            }
            setAddKPI({ ...addKPI })
        }
        else{
            if(props?.data?.find(l=>l.label?.toLowerCase() === addKPI?.value?.toLowerCase())){
                addKPI.error = {
                    value: true,
                    message:'KPI already exists!'
                }
                setAddKPI({ ...addKPI })
            }
            else{
            setAddKPIInput(false);
            props.actionKPI({value:uuid(),label:addKPI.value},'add');;
            setAddKPI({
                value:'',
                error:{
                    value:'',
                    message:''
                }
            })
            }
            // props?.editSectionTitle(titleValue.title,sectionId) && props.editSectionTitle(titleValue.title,sectionId)
        }


    }
}

const blurAndEscEvtForAddKPI = () =>{
    setAddKPIInput(false);
    setAddKPI({
        value:'',
        error:{
            value:''
        }
    })
}

  const deleteKPIFunc = (data) =>{
    props.actionKPI({value:data.value,label:data.label},'delete');
      
  }

  return(
    <div className={classes.root}>
        <Grid container className={classes.containerStyle}>
            <Grid item xs={12} className={`${classes.header}`} >
                <Typography variant="h6" className={classes.titleHead}>Manage KPIs</Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} className={classes.searchField}>
                <TextField 
                    // id="standard-basic"
                    // label="Search for KPI"
                    placeholder="Search for KPI's"
                    fullWidth
                    variant="outlined"
                    onChange={e => onChangeInput(e.target.value)}
                    value={searchValue ?? ''}
                    InputProps={{
                        className: classes.textInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon className={classes.searchBar} />
                            </InputAdornment>
                        ),
                    }}
                    name="searchValue"
                />
            </Grid>
            <Grid item xs={12}>
                <MUList dense className={classes.mulist}>
                    {
                        kpiData && kpiData.map(kpi=>{
                            return(
                                <ListItem
                                    key={kpi.value}
                                    dense
                                    button
                                    onMouseEnter={()=>{
                                        setHover({...hover,
                                                    display:'flex',
                                                    value:kpi.value
                                            })
                                    }}
                                    onMouseLeave={()=>{
                                        setHover({...hover,
                                            display:'none',
                                            value:''
                                        })
                                    }}
                                >
                                    {
                                        editInput && kpi.value === editKPI.value ? (
                                            <TextField 
                                            id="standard-basic"
                                            label="Edit KPI"
                                            fullWidth
                                            autoFocus
                                            onBlur={blurAndEscEvt}
                                            onKeyDown={(e)=>keydownFunc(e,kpi)}
                                            onChange={e => onChangeEditInput(e.target.value)}
                                            value={editKPI.label}
                                            error={editKPI.error.value ?? false}
                                            helperText={
                                                editKPI.error.value === true ? editKPI.error?.message ?? Message.requiredMessage : ''
                                            }
                                        />
                                        
                                        ) : (
                                            <ListItemText primary={kpi.label} onDoubleClick={(e)=>editKPIFunc(e,kpi)} />
                                        )
                                    }
                                   
                                   {
                                       
                                       <div className={classes.icons} style={{
                                           display:hover.value === kpi.value ? hover.display : 'none'
                                       }}>
                                       <ListItemIcon onClick={(e)=>editKPIFunc(e,kpi)}>
                                           <IconButton size="small">
                                               <EditIcon fontSize="small"/>
                                           </IconButton>
                                       </ListItemIcon>
                                       <ListItemIcon onClick={()=>deleteKPIFunc(kpi)}>
                                           <IconButton size="small">
                                               <DeleteIcon fontSize="small" />
                                           </IconButton>
                                       </ListItemIcon>
                                   </div>
                                   }
                            </ListItem>         
                            )
                        })
                    }
                </MUList>
            </Grid>
            <Grid item xs={12} className={classes.spacing}>
                {addKPIInput ? (
                    <div className={classes.addKpiTextInput}>
                        <TextField 
                            id="standard-basic"
                            label="Add KPI"
                            fullWidth
                            autoFocus
                            onBlur={blurAndEscEvtForAddKPI}
                            onKeyDown={(e)=>keydownFuncForAddKPI(e,addKPI.value)}
                            onChange={e => onChangeAddInput(e.target.value)}
                            value={addKPI.value}
                            error={addKPI.error.value ?? false}
                            helperText={
                                addKPI.error.value === true ? addKPI.error?.message ?? Message.requiredMessage : ''
                            }
                    />
                </div>
                ) : (
                    <Button variant='text' className={classes.addBtn} onClick={()=>setAddKPIInput(true)}>+ Add KPI</Button>
                )}
            </Grid>
        </Grid>
    </div>
  );
};

ManageKPI.propTypes = {
  data: PropTypes.array,
  
};

ManageKPI.defaultProps = {

};
