/**
 * @author Sri Rahul
 * @email rahul@qdmplatforms.com
 * @create date 2021-02-24 14:51:22
 * @modify date 2021-02-24 14:51:22
 * @desc [description]
 */

import React from 'react';
import { Typography,TextField,Button,IconButton,ClickAwayListener} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import {Message} from '../../utils';
import {PopoverComponent} from '../../components'
import { SectionContext } from '../../contexts'
import {UserList} from './userList';

const useStyles = makeStyles(theme => ({
    root: {
        padding:'8px 16px'
    },
    flexCenterBetween:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    flexCenter:{
        display:'flex',
        alignItems:'center',
        marginBottom:10
    },
    answerDiv:{
        backgroundColor:'#F3F3F3',
        padding: theme.spacing(1),
        borderRadius:7,
        marginBottom:10,
        '& .MuiTypography-body2':{
            color:'#1F1D52',
            fontSize:14
        }
    },
      questionSection:{
          padding:theme.spacing(1),
          backgroundColor:'#F7F7F7',
          borderRadius:5,
          marginBottom:theme.spacing(2),  
      },
      addBtn:{
        color:'#E34D65',
        padding:0,
        display:'flex',
        justifyContent:'flex-start',
        '&:hover':{
            background:'none'
        }
    },
    addAnswerBtn:{
        backgroundColor:'#F3F3F3',
        padding:'5px 10px',
        borderRadius:7,
        margin:'7px 0px',
        marginBottom:'10px'

    },
    addQuestionSection:{
        marginBottom:10,
        backgroundColor:'#F3F3F3',
        padding:'0px 10px',
        borderRadius:7
    }
}));

export const SectionBody = (props) => {
        
        const classes = useStyles(props);   
        
        const [state, setState] = React.useState({
            answer:props?.answer ?? '',
            questionID:'',
            sectionID:'',
            questionName:props?.questionName ?? '',
            error:{
            answer:false,
            questionName:false
        }
    })
    
    const onChangeInput = e => {
        setState({ ...state, [e.target.name]: e.target.value})
    }
    
        //show or hide answer input
        const [showInput,setShowInput] = React.useState(false);

        const sectionContext = React.useContext(SectionContext);
        const {list,setSectionArray,KPI,strategic_master} = sectionContext;

        // console.log(list,'body')
        
        const addAnswerToQuestion = (questionId,sectionId) =>{       
            if(state.answer.length === 0){
                state.error = {
                    answer: state?.answer?.length === 0 ? true : false,
                }
                setState({ ...state })
            }
            
            else{
                list && list.forEach(_=>{
                    if(_.section_id === sectionId){
                        _.questions && _.questions.forEach(question=>{
                            if(question.question_id === questionId){
                                    question.answer=state.answer
                                    props.setIsEdit(true);
                            }
                        })
                    }
                })

                setSectionArray({
                    ...sectionContext,
                    list
                })

                setShowInput(false)

        }
        }

        const addAnswer = (sectionId) =>{
            setState({ ...state, sectionID: sectionId,answer:''})
            setShowInput(true)
        }

        const keydownFunc = (event) =>{
            if(event.keyCode === 27) {
                blurAndEscEvtForAnswer()
                
            }
            else if(event.keyCode === 13) {
                addAnswerToQuestion(props?.questionId,props?.sectionId);
                props.setIsEdit(true);
                setShowAnswerInput(false)
                
            }
          }

          const blurAndEscEvtForAnswer = () =>{
                setShowInput(false);
                setShowAnswerInput(false);
                setState({...state,error:{
                    answer:false,
                }})
          }


        const deleteQuestions = (questionId,sectionId) =>{
        
            list && list.forEach(_=>{
                if(_.section_id === sectionId){
                    const filteredQuestions = _.questions && _.questions.filter(question=>question.question_id !== questionId);
                    _.questions=[...filteredQuestions];
                    props.setIsEdit(true);
                }
            })

            setSectionArray({
                ...sectionContext,
                list
            })

        }

        const changeAnswerType = (sectionId,value,questionId) =>{
        
            list && list.forEach(_=>{
                if(_.section_id === sectionId){
                    _.questions && _.questions.forEach(question=>{
                        if(question.question_id === questionId){
                                question.answer_type=value
                                props.setIsEdit(true);
                        }
                    })
                }
            })
    
            setSectionArray({
                ...sectionContext,
                list
            })
    
        }

        const changeAnswerType2 = (sectionId,value,questionId) =>{
            
            list && list.forEach(_=>{
                if(_.section_id === sectionId){
                    _.questions && _.questions.forEach(question=>{
                        if(question.question_id === questionId){
                                question.answer_type2=value
                                props.setIsEdit(true);
                        }
                    })
                }
            })
    
            setSectionArray({
                ...sectionContext,
                list
            })
    
        }
        

        const [answerType,setAnswerType] = React.useState(props?.answerType ?? {})
        const [answerType2,setAnswerType2] = React.useState(props?.answerType2 ?? {})

        React.useEffect(()=>{
            setAnswerType2(props?.answerType2 ?? {})
        },[props.answerType2])

        const onChangeAnswerType = (data) =>{
            if(answerType.value === data.value){
                setAnswerType({})
                changeAnswerType(props?.sectionId,{},props?.questionId)
            }
            else{
                setAnswerType(data)
                changeAnswerType(props?.sectionId,data,props?.questionId)
            }
        }

        const onChangeAnswerType2 = (data) =>{
            if(answerType2.value === data.value){
                setAnswerType2({})
                changeAnswerType2(props?.sectionId,{},props?.questionId)
            }
            else{
                setAnswerType2(data)
                changeAnswerType2(props?.sectionId,data,props?.questionId)
            }
        }

        const usersList = [
            {
                user_id:'userID1',
                user_name:'Ashwin',
                user_pic:'https://www.w3schools.com/howto/img_avatar.png'
            },
            {
                user_id:'userID2',
                user_name:'Kiran',
                user_pic:'https://www.w3schools.com/howto/img_avatar.png'
            },
            {
                user_id:'userID3',
                user_name:'Kumar',
                // user_pic:'https://www.w3schools.com/howto/img_avatar.png'
            },
            {
                user_id:'userID4',
                user_name:'Gokul',
                user_pic:'https://www.w3schools.com/howto/img_avatar.png'
            },
        ];
       
        const addUser = (sectionId,questionId,data) =>{
            
            list && list.forEach(_=>{
                if(_.section_id === sectionId){
                    _.questions && _.questions.forEach(question=>{
                        if(question.question_id === questionId){
                            if(Array.isArray(question.users)){
                                
                                // const check = question.users.find(d=>d.user_id === data.user_id);
                                // if(check){
                                //     const filteredData = question.users.filter(_=>_.user_id !== data.user_id);
                                //     question.users = [...filteredData]
                                // }
                                // else{
                                //     question.users.push(data) 
                                // }
                                question.users = data
                            }
                            else{
                                
                                question["users"] = data
                            }
                            props.setIsEdit(true);
                        }
                    })
                }
            })
    
            setSectionArray({
                ...sectionContext,
                list
            })

        }
        const [showQuestionInput,setShowQuestionInput] = React.useState(false);


        const dblClkEditQuestion = () =>{

            setShowQuestionInput(true);

            state.error = {
                questionName: false,
            }
            setState({ ...state })
        }

        const blurAndEscEvtForQuestion = () =>{
            setShowQuestionInput(false);
            setState({...state,questionName:props?.questionName})
        }

        const editQuestion = (event,questionId,sectionId) =>{

            if(event.keyCode === 27) {
                blurAndEscEvtForQuestion()
            }
            else if(event.keyCode === 13) {                

                if(state.questionName.length === 0){
                    state.error = {
                        questionName: state?.questionName?.length === 0 ? true : false,
                    }
                    setState({ ...state })
                }
                else{
                    setShowQuestionInput(false);
                    list && list.forEach(_=>{
                        if(_.section_id === sectionId){
                            _.questions && _.questions.forEach(question=>{
                                if(question.question_id === questionId){
                                        question.question_name=state.questionName
                                        props.setIsEdit(true);
                                }
                            })
                        }
                    })
            
                    setSectionArray({
                        ...sectionContext,
                        list
                    })
                }


            }
        }

        const [showAnswerInput,setShowAnswerInput] = React.useState(false);

        const dblClkEditAnswer = () =>{
            state.error = {
                answer: false,
            }
            setState({ ...state,answer:props?.answer })
            setShowAnswerInput(true)
        }

        return (
            <div className={classes.root}>
               <div>
                    <div className={classes.flexCenterBetween}>
                        {
                            showQuestionInput ? (
                            <TextField
                                autoFocus
                                onChange={e => onChangeInput(e)}
                                value={state.questionName}
                                fullWidth
                                name="questionName"
                                label="Question"
                                margin="dense"
                                onKeyDown={(e)=>editQuestion(e,props?.questionId,props?.sectionId)}
                                onBlur={blurAndEscEvtForQuestion}
                                error={state.error.questionName}
                                helperText={
                                    state.error.questionName === true ? Message.requiredMessage : ''
                            }
                            />
                            ) : (
                                <Typography variant="h6" style={{fontSize:15,cursor:'pointer'}} onDoubleClick={dblClkEditQuestion}>{props?.questionName}</Typography>
                            )
                        }
                    
                    <IconButton size="small" onClick={()=>deleteQuestions(props?.questionId,props?.sectionId)}>
                        <DeleteOutlineIcon fontSize="small"/>
                    </IconButton>
                    </div>
                    
                    {
                        props?.answer && props?.answer.length > 0 ?(
                        <div className={classes.answerDiv}>
                            {
                                showAnswerInput ? (
                                    <TextField
                                        autoFocus
                                        onChange={e => onChangeInput(e)}
                                        value={state.answer}
                                        fullWidth
                                        multiline
                                        rowsMax={4}
                                        name="answer"
                                        label="Answer"
                                        margin="dense"
                                        onKeyDown={(e)=>keydownFunc(e)}
                                        onBlur={blurAndEscEvtForAnswer}
                                        error={state.error.answer}
                                        helperText={
                                            state.error.answer === true ? Message.requiredMessage : ''
                                        }
                                    />
                                ) : (
                                    <Typography variant="body2" style={{cursor:'pointer'}} onDoubleClick={dblClkEditAnswer}>{props?.answer}</Typography>
                                )
                            }
                        </div>  ) : (
                            <div>
                                {
                                showInput && props?.sectionId === state.sectionID &&
                                <div>
                                    <div className={classes.addQuestionSection} style={{
                                        marginBottom:10,
                                        backgroundColor:'#F3F3F3',
                                        padding:'0px 10px',
                                        borderRadius:7
                                    }}>
                                        <ClickAwayListener onClickAway={blurAndEscEvtForAnswer}>
                                            <TextField
                                                autoFocus
                                                onChange={e => onChangeInput(e)}
                                                value={state.answer}
                                                fullWidth
                                                name="answer"
                                                label="Answer"
                                                margin="dense"
                                                onKeyDown={(e)=>keydownFunc(e)}
                                                // onBlur={blurAndEscEvtForAnswer}
                                                error={state.error.answer}
                                                helperText={
                                                    state.error.answer === true ? Message.requiredMessage : ''
                                                }
                                                />
                                            </ClickAwayListener>
                                            <Button variant='text' className={classes.addBtn} onClick={()=>addAnswerToQuestion(props?.questionId,props?.sectionId)}>Add</Button>
                                    </div>
                                 </div>
                                }
                                {(!showInput || (props?.sectionId !== state.sectionID)) &&
                                <div className={classes.addAnswerBtn}>
                                    <Button variant='text' className={classes.addBtn} onClick={()=>addAnswer(props?.sectionId)}>
                                        + Add Answer
                                    </Button>
                                </div>
                                }
                            </div>
                        )
                    }

                    <div className={`${classes.flexCenter}`} style={{flexWrap:'wrap'}}>
                        <div style={{marginRight:5}}>
                        <PopoverComponent 
                            data={strategic_master}
                            selectedValue={answerType}
                            label="+ Strategic/Objective"
                            onChange={onChangeAnswerType}
                        />
                        </div>
                            <PopoverComponent 
                                data={KPI}
                                selectedValue={answerType2}
                                label="+ KPI"
                                onChange={onChangeAnswerType2}
                            />
                    </div>
                    <div>
                        <UserList 
                            userList={usersList}
                            selectedUsers={props?.users}
                            sectionId={props?.sectionId}
                            questionId={props?.questionId}
                            addUser={addUser}
                        />
                    </div>
                </div>
            </div>
        )
}