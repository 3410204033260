/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc Collection of all the message used in the application.
 */

//Normal messagess
export let Message = {
  requiredMessage: "This field is required",
  saveSuccess:'Thanks for Saving',
  infoSuccess:'Please save your changes!',
};
