/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020 
 * @desc withAllContexts HOC will send all the necessary Context such as AlertContext,
 * DialogContext, BackdropContext and DrawerContext as a prop.
 */

import React from "react";
import { AlertContext, DialogContext, BackdropContext, DrawerContext,SectionContext } from "../contexts";


const withAllContexts = (Component) => (props) => {
  const alert = React.useContext(AlertContext);
  const dialog = React.useContext(DialogContext);
  const backDrop = React.useContext(BackdropContext);
  const drawer = React.useContext(DrawerContext);
  const sectionContext = React.useContext(SectionContext);
  
  return (
    <Component {...props} alert={alert} dialog={dialog} backDrop={backDrop} drawer={drawer} sectionContext={sectionContext} queryParams={{}}>
      {props.children}
    </Component>
  );
};

export default withAllContexts;
